@import 'shared/shared';
@import './node_modules/@nbcnews/design-tokens/tokens/msnbc/breakpoints';
@import './node_modules/@nbcnews/design-tokens/tokens/msnbc/colors';
@import './node_modules/@nbcnews/design-tokens/tokens/msnbc/colorsRGB';
@import './node_modules/@nbcnews/design-tokens/tokens/msnbc/fontFamilies';
@import './node_modules/@nbcnews/design-tokens/tokens/msnbc/fontSizes';
@import './node_modules/@nbcnews/design-tokens/tokens/msnbc/fontWeights';
@import './node_modules/@nbcnews/design-tokens/tokens/msnbc/gradients';
@import './node_modules/@nbcnews/design-tokens/tokens/msnbc/letterSpacings';
@import './node_modules/@nbcnews/design-tokens/tokens/msnbc/lineHeights';
@import './node_modules/@nbcnews/design-tokens/tokens/msnbc/opacities';
@import './node_modules/@nbcnews/design-tokens/tokens/msnbc/spacing';

// GLOBAL VARIABLES
$theme: 'msnbc';

// Background
$default-background: $white;

// Colors
$default-color: $blue-40; // Historically: clear-blue #3061ff
$default-neutral: $neutral-10;

// Gradient
$default-gradient: to-list($blue-teal-gradient);
$gradient-value-1: rgba(16, 32, 57, .8);
$gradient-value-2: rgba(48, 97, 255, .6);

// Images
$default-img-border-radius: 0%;

// Links
$default-hover-opacity: $opacity-70;
$default-active-opacity: $opacity-50;

// Headlines
$default-headline-color: $grey-70;
$default-headline-leading: $leading-100;
$default-headline-font-family: $founders-cond;

// Body text
$default-text-size: $text-18;
$default-text-color: $grey-70;
$default-text-leading: $leading-150;
$default-text-font-family: $publico-txt;

// Header
$default-local-insert-flex-basis: 120px;

:root {
  /* Colors */
  --default-color: var(--blue-40);
  --default-color-rgb: var(--blue-40-rgb);
  --default-neutral: var(--neutral-10);
  --default-neutral-rgb: var(--neutral-10-rgb);
  --primary-color: var(--default-color);
  --primary-color-rgb: var(--default-color-rgb);

  /* Gradient */
  --default-gradient: var(--blue-teal-gradient-stop-1), var(--blue-teal-gradient-stop-2);
  --gradient-value-1: var(--blue-teal-gradient-stop-1);
  --gradient-value-1-rgb: 19, 82, 112;
  --gradient-value-2: var(--blue-teal-gradient-stop-2);
  --gradient-value-2-rgb: 81, 241, 226;

  /* Images */
  --default-img-border-radius: 0%;

  /* Links */
  --default-hover-opacity: var(--opacity-70);
  --default-active-opacity: var(--opacity-50);

  /* Headlines */
  --default-headline-color: var(--grey-70);
  --default-headline-color-rgb: var(--grey-70-rgb);
  --default-headline-leading: var(--leading-100);
  --default-headline-font-family: var(--founders-cond);
  --primary-font: var(--default-headline-font-family);

  /* Body text */
  --default-text-size: var(--text-18);
  --default-text-color: var(--grey-70);
  --default-text-color-rgb: var(--grey-70-rgb);
  --default-text-leading: var(--leading-150);
  --default-text-font-family: var(--publico-txt);
  --secondary-font: var(--default-text-font-family);

  /* Header */
  --default-local-insert-flex-basis: 120px;
}

body.msnbc {
  /* Default overrides */
  .multistoryline3-package {
    --package-spacing: 20px;
  }
}
